.FrameworkEvidenceDescription {

    margin: 6px 0px;
    cursor: inherit;
    text-decoration: none;
    white-space: normal;
    text-overflow: clip;

    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    color: #605E5C;


}


.FrameworkControlDescription {
    user-select: auto;
    margin: 0px;
    cursor: auto;
    text-decoration: none;
    text-overflow: clip;

    font-family: Noto Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
    color: #605E5C;
}


.FrameworkControlDescription * strong, .FrameworkEvidenceDescription * strong {
    font-weight: 600;
}
.FrameworkControlDescription p, .FrameworkEvidenceDescription p,
.FrameworkControlDescription * p, .FrameworkEvidenceDescription * p {
    margin-bottom: 7px;
}
.FrameworkControlDescription ul, .FrameworkEvidenceDescription ul,
.FrameworkControlDescription * ul, .FrameworkEvidenceDescription * ul{
    margin-top: 10px;
}
.FrameworkControlDescription * li, .FrameworkEvidenceDescription * li {
    list-style-type: auto;
    margin-left: 17px;
}
.FrameworkControlDescription * li, .FrameworkEvidenceDescription * li {
    margin-bottom: 4px;
}
